import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';

@Injectable({
    providedIn: 'root'

})
export class RestService {
    
    public API_KEY:any              = "2992c9debd79d95734hj89234hx234";
    // public manu_icon_default:any    = "http://localhost/in2in/front/icon_image/"; 
    // public BaseUrl:any              = 'http://localhost/in2in/api/';
    // public assets_url:any           = 'http://localhost/in2in/upload/documents/';
    // public pdf_url:any              = 'http://localhost/in2in/front/pdf/';
    // public preview_url:any          = 'http://localhost/in2in/front/template_preview_image/';

    public manu_icon_default:any    = "https://in2in.in/front/icon_image/"; 
    public BaseUrl:any              = 'https://in2in.in/api/';
    public assets_url:any           = 'https://in2in.in/upload/documents/';
    public pdf_url:any              = 'https://in2in.in/front/pdf/';
    public preview_url:any          = 'https://in2in.in/front/template_preview_image/';

    constructor(
        private http: HttpClient
    
    ) {

    }

    postData(data: any, method: any): Observable<any> {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        
        return this.http.post<any>(this.BaseUrl + method, JSON.stringify(data), {headers:headers}).pipe(
            tap((data) => console.log()
            ),
            catchError(this.handleError<any>('Login'))
        );
    }
    

    private handleError<T>(operation = 'operation', result?: T) {

        return (error: any): Observable<T> => {
            return of(result as T);
        };
    }



}

<span id="tags_campaign_id" class="{{campaign?.form_id}}"></span>
<span id="tags_current_page_id" class="{{current_page?.form_id}}"></span>

<ng-container *ngIf="campaign?.front_template == 2">
    <style>
        .carousel-item img {
            height: 80vh;
            width: 100%;
        }
        @media (max-width: 576px) {
            .carousel-item img {
                height: 40vh;
            }
        }
    </style>

    <header id="header" class="fixed-top" [ngStyle]="{'background-color': campaign.menu_background_color ? campaign.menu_background_color : '#2196f3' }">
        <div class="container">
            <div class="logo float-left">
                <a href="{{newstr}}" class="scrollto"><img src="{{assets_url}}{{campaign.logo}}" alt="" class="img-fluid"></a>
            </div>

            <nav class="main-nav float-right d-none d-lg-block" id="mobile_nav">
                <ul>
                    <ng-container *ngFor="let menudata of all_pages">

                        <ng-container *ngIf="!menudata.children || menudata.children.length == 0">
                            <li class="active" [ngStyle]="{'background-color': menu == menudata.parents?.menu_value ? '#9d9d9d' : '' }">
                                <a [href]="menudata.parents?.menu_value" [ngStyle]="{'color': campaign.menu_color ? campaign.menu_color : '#fff' }">
                                    
                                    <ng-container *ngIf="menudata.parents?.content_type =='Infographic'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != '' && menudata.parents.menu_icon != NULL; else Infographic">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Infographic>
                                            <i class="fa fa-info-circle" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='video'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != '' && menudata.parents.menu_icon != NULL; else video">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #video>
                                            <i class="fa fa-file-video-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Image'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != '' && menudata.parents.menu_icon != NULL; else Image">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Image>
                                            <i class="fa fa-picture-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Landing Page'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != ''  && menudata.parents.menu_icon != NULL; else Landing_Page">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Landing_Page>
                                            <i class="fa fa-globe" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Ebook'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != '' && menudata.parents.menu_icon != NULL; else Ebook">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Ebook>
                                            <i class="fa fa-book" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Case Study'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != '' && menudata.parents.menu_icon != NULL; else Case_Study">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Case_Study>
                                            <i class="fa fa-eercast" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='White Paper'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != ''  && menudata.parents.menu_icon != NULL; else White_Paper">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #White_Paper>
                                            <i class="fa fa-newspaper-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Article'">
                                        <ng-container *ngIf="menudata.parents.menu_icon != ''  && menudata.parents.menu_icon != NULL; else Article">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Article>
                                            <i class="fa fa-fax" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>
                                    {{menudata.parents?.manu_title}}
                                </a>
                            </li>
                        </ng-container>
                        
                        <ng-container *ngIf="menudata.children && menudata.children.length > 0">
                            <li class="drop-down" [ngStyle]="{'background-color': menu == menudata.parents?.menu_value ? '#9d9d9d' : '' }">
                                <a [href]="menudata.parents?.menu_value" [ngStyle]="{'color': campaign.menu_color ? campaign.menu_color : '#fff' }">
                                    
                                    <ng-container *ngIf="menudata.parents?.content_type =='Infographic'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Infographic">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Infographic>
                                            <i class="fa fa-info-circle" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Image'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Image">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Image>
                                            <i class="fa fa-picture-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='video'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else video">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #video>
                                            <i class="fa fa-file-video-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Landing Page'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Landing_Page">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Landing_Page>
                                            <i class="fa fa-globe" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Ebook'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Ebook">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Ebook>
                                            <i class="fa fa-book" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Case Study'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Case_Study">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Case_Study>
                                            <i class="fa fa-eercast" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='White Paper'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else White_Paper">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #White_Paper>
                                            <i class="fa fa-newspaper-o" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="menudata.parents?.content_type =='Article'">
                                        <ng-container *ngIf="menudata.parents.menu_icon !=''  && menudata.parents.menu_icon != NULL; else Article">
                                            <i class="{{menudata.parents.menu_icon}}" id="icon_mohit"></i>
                                        </ng-container>
                                        <ng-template #Article>
                                            <i class="fa fa-fax" id="icon_mohit"></i>
                                        </ng-template>
                                    </ng-container> {{menudata.parents?.manu_title}}
                                </a>

                                <ul>
                                    <li id="child" [ngStyle]="{'background-color': menu == q?.menu_value ? '#9d9d9d' : '' }" *ngFor="let q of menudata.children;let i = index" id="li_border">
                                        <a [href]="q?.menu_value">

                                            <ng-container *ngIf="q?.content_type =='Infographic'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Infographic">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Infographic>
                                                    <i class="fa fa-info-circle" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='Image'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Image">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Image>
                                                    <i class="fa fa-picture-o" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='video'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else video">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #video>
                                                    <i class="fa fa-file-video-o" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='Landing Page'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Landing_Page">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Landing_Page>
                                                    <i class="fa fa-globe" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='Ebook'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Ebook">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Ebook>
                                                    <i class="fa fa-book" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='Case Study'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Case_Study">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Case_Study>
                                                    <i class="fa fa-eercast" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='White Paper'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else White_Paper">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #White_Paper>
                                                    <i class="fa fa-newspaper-o" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container>

                                            <ng-container *ngIf="q?.content_type =='Article'">
                                                <ng-container *ngIf="q.menu_icon !=''  && q.menu_icon != NULL; else Article">
                                                    <i class="{{q.menu_icon}}" id="icon_mohit"></i>
                                                </ng-container>
                                                <ng-template #Article>
                                                    <i class="fa fa-fax" id="icon_mohit"></i>
                                                </ng-template>
                                            </ng-container> {{q?.manu_title}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </nav>
        </div>
    </header>

    <div class="containerfor_top"></div>
    
    <main id="main">

        <ul id="social-sidebar" *ngIf="contact_form_campaign?.name != '' && contact_form_campaign?.name != null">
            <li>
                <a class="entypo-twitter" (click)="open_campaign_form()"><i class="fa fa-envelope  text-dark"></i> 
                    <span class="">{{contact_form_campaign?.name}}</span>
                </a>
            </li>
        </ul>
       
        <ng-container *ngIf="this.current_page.banner != ''  && this.current_page.banner != 0 && current_page.banner_position === 'top' && banner_data != ''">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators" *ngIf="banner_data?.length > 1 && banner_data != ''">
                    <ng-container *ngFor="let banner of banner_data; let a = index">
                        <li data-target="#carouselExampleIndicators" [aria-current]="(i === 0) ? 'true' : 'false'"
                            [class]="(a === 0) ? 'active' : ''" data-slide-to="a"></li>
                    </ng-container>
                </ol>

                <div class="carousel-inner">
                    <div class="carousel-item" data-bs-interval="10000" *ngFor="let banner of banner_data; let i=index" [class]="(i === 0) ? 'active' : ''">
                        <img src="{{assets_url}}{{banner.banner_image}}" class="d-block w-100 banner_image" alt="...">
                        <div class="carousel-caption d-md-block">
                            <a target="_blank" href="{{banner.banner_url}}" (click)="banner_report(banner.form_id)" style="text-decoration: none;">
                                <h2 class="banner_title banner_title_change">{{banner.banner_title}}</h2>
                            </a>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="banner_data?.length > 1 && banner_data != ''">
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </ng-container>
            </div>
        </ng-container>

        <section class="resume-section dynamic_page" id="datachange0" style="width: 100%; margin: auto;">

            <ng-container *ngIf="current_page.page_type === 'Image'">
                <ng-container *ngIf="current_page.page_image != ''; else noimage">
                    <img class="img-fluid  mx-auto mb-2 imagebanner" src="{{assets_url}}{{current_page.page_image}}" alt="" style="width: 100%;">
                </ng-container>
                <ng-template #noimage>
                    <h3>Image Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Editor'" class="dynamic_page_container">
                <ng-container *ngIf="current_page.page_editor != ''; else noeditor">
                    <p class="">
                        <span [innerHTML]="sanitized_html" class="dynamic_page_span"></span>
                    </p>
                </ng-container>
                <ng-template #noeditor>
                    <h3>HTML Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'PDF'">
                <ng-container *ngIf="current_page.page_pdf != ''; else nopdf">
                    <div *ngIf="loaderview">
                        <div class="loader-new"></div>
                        <h3 class="loader-text">Downloading PDF Please Wait...</h3>
                    </div>

                    <div class="pdf-container">
                        <pdf-viewer [src]="pdf_url+current_page.page_pdf" [show-all]="true" [autoresize]="true"
                            [original-size]='true' [fit-to-page]='true' [render-text]='false'
                            (after-load-complete)="callBackFn($event)">
                        </pdf-viewer>
                    </div>
                </ng-container>
                <ng-template #nopdf>
                    <h3>PDF Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Audio'">
                <ng-container *ngIf="current_page.page_audio != ''; else noaudio">
                    <audio class="audio-controls" controls>
                        <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/ogg">
                        <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/mpeg">
                    </audio>
                </ng-container>
                <ng-template #noaudio>
                    <h3>Audio Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'url'">
                <ng-container *ngIf="current_page.page_url != ''; else nopageurl">
                    <iframe width="100%" height="750" [src]="embeded_page_url" frameborder="0" allow=""></iframe>
                </ng-container>
                <ng-template #nopageurl>
                    <h3>Url Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'embeded_video_url'">
                <ng-container *ngIf="current_page.page_embeded_video_url != ''; else noevideourl">
                    <iframe width="100%" style="min-height: 90vh;" [src]="embeded_video_url" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </ng-container>
                <ng-template #noevideourl>
                    <h3>Embeded Video Url Not Found!</h3>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Video'">
                <ng-container *ngIf="current_page.page_video != ''; else novideo">
                    <video width="100%" height="540" controls autoplay>
                        <source src="{{assets_url}}{{current_page.page_video}}" type="video/mp4">
                        <source src="{{assets_url}}{{current_page.page_video}}" type="video/ogg">
                    </video>
                </ng-container>
                <ng-template #novideo>
                    <h3>Video Not Found!</h3>
                </ng-template>
            </ng-container>
        </section>

        <ng-container *ngIf="this.current_page.banner != '' && this.current_page.banner != 0 && current_page.banner_position === 'bottom' && banner_data != ''">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators" *ngIf="banner_data?.length > 1 && banner_data != ''">
                    <ng-container *ngFor="let banner of banner_data; let a = index">
                        <li data-target="#carouselExampleIndicators" [aria-current]="(i === 0) ? 'true' : 'false'" [class]="(a === 0) ? 'active' : ''" data-slide-to="a"></li>
                    </ng-container>
                </ol>

                <div class="carousel-inner">
                    <div class="carousel-item" data-bs-interval="10000" *ngFor="let banner of banner_data; let i=index" [class]="(i === 0) ? 'active' : ''">
                        <img src="{{assets_url}}{{banner.banner_image}}" class="d-block w-100 banner_image" alt="...">
                        <div class="carousel-caption d-md-block">
                            <a target="_blank" href="{{banner.banner_url}}" (click)="banner_report(banner.form_id)" style="text-decoration: none;">
                                <h2 class="banner_title banner_title_change">{{banner.banner_title}}</h2>
                            </a>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="banner_data?.length > 1 && banner_data != ''">
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </ng-container>
            </div>
        </ng-container>

        <section class="resume-section p-3 p-lg-5 d-flex flex-column" *ngIf="contact_form && contact_form != null && current_page && current_page != null &&  current_page.contact_form_view_type == 'modal'">
            <div class="row">
                <div class="col-4">
                    <button mat-raised-button (click)="openDialog()" color="{{contact_form.submite_button_color}}">{{contact_form.name}}</button>
                </div>
            </div>
        </section>
        
        <section class="resume-section p-3 p-lg-5 d-flex flex-column" *ngIf="contact_form && contact_form != null && current_page && current_page != null &&  current_page.contact_form_view_type == 'onpage'">

            <!-- <========================================== Simple Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'simple_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">
                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <mat-card-header>
                                    <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                </mat-card-header>

                                <mat-card-header>
                                    <mat-card-title>
                                        <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                        <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                    </mat-card-title>
                                </mat-card-header>

                                <mat-card-content *ngIf="contact_form_field && contact_form_field != null">
                                    <mat-input-container>
                                        <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">

                                            <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>
                                                
                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.language">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.allow">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>
                                                
                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                <div>
                                                    <mat-toolbar>
                                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                        <button type="button" mat-flat-button> Browse File </button>
                                                    </mat-toolbar>

                                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                </div>
                                            </mat-form-field>

                                            <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                            </p>
                                        </div>

                                        <ng-container *ngIf="contact_form.submit_button_name != ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                        </ng-container>

                                        <ng-container *ngIf="contact_form.submit_button_name == ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                        </ng-container>

                                        <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>

                                    </mat-input-container>
                                </mat-card-content>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <========================================== Left Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'left_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">

                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <div class="col-6" style="float: left;padding-left: 0px;">
                                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                </div>

                                <div class="col-6" style="float: right;padding-right: 0px;">

                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>

                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">
                                        <mat-input-container>
                                            <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">
    
                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">
    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.education"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.salary"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>
    
                                                        <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                    </div>
                                                </mat-form-field>
    
                                                <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                                </p>
                                            </div>
    
                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>
    
                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
    
                                            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>
    
                                        </mat-input-container>
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <========================================== Right Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'right_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">
                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <div class="col-6" style="float: left;padding-left: 0px;">
                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>

                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                        <mat-input-container>
                                            <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">
    
                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">
    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.education"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.salary"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>
    
                                                        <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                    </div>
                                                </mat-form-field>
    
                                                <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                                </p>
                                            </div>
    
                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>
    
                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
    
                                            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>
    
                                        </mat-input-container>
                                    </mat-card-content>
                                </div>

                                <div class="col-6" style="float: right;padding-right: 0px;">
                                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                </div>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <========================================== Top Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'top_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}" style="width: 25%;">
                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                
                                <mat-card-header>
                                    <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                </mat-card-header>

                                <mat-card-header>
                                    <mat-card-title>
                                        <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                        <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                    </mat-card-title>
                                </mat-card-header>

                                <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                    <mat-input-container>
                                        <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">

                                            <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>
                                                
                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.language">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.allow">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                                        value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>
                                                
                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                                    placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                <div>
                                                    <mat-toolbar>
                                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                        <button type="button" mat-flat-button> Browse File </button>
                                                    </mat-toolbar>

                                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                </div>
                                            </mat-form-field>

                                            <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                            </p>
                                        </div>

                                        <ng-container *ngIf="contact_form.submit_button_name != ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                        </ng-container>

                                        <ng-container *ngIf="contact_form.submit_button_name == ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                        </ng-container>

                                        <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>

                                    </mat-input-container>
                                </mat-card-content>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>

    <footer id="footer" class="" [ngStyle]="{'background-color': campaign.menu_background_color ? campaign.menu_background_color : '#2196f3' }">
        <div class="footer-top" [ngStyle]="{'background-color': campaign.menu_background_color ? campaign.menu_background_color : '#2196f3' }">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 footer-info text-center">
                        <div class="logo text-center">
                            <a href="{{newstr}}" class="scrollto"><img src="{{assets_url}}{{campaign.logo}}" alt="" class="img-fluid" id="footer_img" style="max-width: 60%; margin-top: 10px;height: 50px;"></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6  footer-links">

                    </div>

                    <div class="col-lg-4 col-md-6  footer-contact">
                        <div class="social-links text-center">
                            <style>
                                .fa {
                                    margin-top: -6px;
                                }
                            </style>
                            <ul class="list-inline list-social-icons mb-0">
                                <li class="list-inline-item" *ngIf="campaign?.facebooklink !='' ">
                                    <a href="{{campaign?.facebooklink}}" target="_blank">
                                        <span class="fa-stack">
                                            <i class="fa fa-facebook fa-stack-1x"></i>
                                        </span>
                                    </a>
                                </li>

                                <li class="list-inline-item" *ngIf="campaign?.twitterlink !=''">
                                    <a href="{{campaign?.twitterlink}}" target="_blank">
                                        <span class="fa-stack">
                                            <i class="fa fa-twitter fa-stack-1x"></i>
                                        </span>
                                    </a>
                                </li>

                                <li class="list-inline-item" *ngIf="campaign?.linkedinlink !=''">
                                    <a href="{{campaign?.linkedinlink}}" target="_blank">
                                        <span class="fa-stack">
                                            <i class="fa fa-linkedin fa-stack-1x"></i>
                                        </span>
                                    </a>
                                </li>

                                <li class="list-inline-item" *ngIf="campaign?.youtubelink !=''">
                                    <a href="{{campaign?.youtubelink}}" target="_blank">
                                        <span class="fa-stack">
                                            <i class="fa fa-youtube-play fa-stack-1x"></i>
                                        </span>
                                    </a>
                                </li>

                                <li class="list-inline-item" *ngIf="campaign?.mail !=''">
                                    <a href="mailto:{{campaign?.mail}}" target="_blank">
                                        <span class="fa-stack">
                                            <i class="fa fa-envelope  fa-stack-1x"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</ng-container>

<ng-container *ngIf="campaign?.front_template == 1">
    <div class="body" *ngIf="isLoading == false">
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="sideNav" [ngStyle]="{'background-color': campaign.menu_background_color ? campaign.menu_background_color : '#2196f3' }">

            <a class="navbar-brand js-scroll-trigger" href="{{newstr}}">
                <span class="d-block d-lg-none  mx-0 px-0">
                    <img src="{{assets_url}}{{campaign.logo}}" alt="" class="img-phone img-fluid">
                </span>
                <span class="d-none d-lg-block">
                    <img src="{{assets_url}}{{campaign?.logo}}" class="img-fluid img-profile mx-auto mb-2" alt="">
                </span>
            </a>

            <ul class="list-inline list-social-icons mb-0">
                <li class="list-inline-item" *ngIf="campaign?.facebooklink !='' ">
                    <a href="{{campaign?.facebooklink}}" target="_blank">
                        <span class="fa-stack">
                            <i class="fa fa-facebook fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

                <li class="list-inline-item" *ngIf="campaign?.twitterlink !=''">
                    <a href="{{campaign?.twitterlink}}" target="_blank">
                        <span class="fa-stack">
                            <i class="fa fa-twitter fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

                <li class="list-inline-item" *ngIf="campaign?.linkedinlink !=''">
                    <a href="{{campaign?.linkedinlink}}" target="_blank">
                        <span class="fa-stack">
                            <i class="fa fa-linkedin fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

                <li class="list-inline-item" *ngIf="campaign?.youtubelink !=''">
                    <a href="{{campaign?.youtubelink}}" target="_blank">
                        <span class="fa-stack">
                            <i class="fa fa-youtube-play fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

                <li class="list-inline-item" *ngIf="campaign?.mail !=''">
                    <a href="mailto:{{campaign?.mail}}" target="_blank">
                        <span class="fa-stack">
                            <i class="fa fa-envelope  fa-stack-1x"></i>
                        </span>
                    </a>
                </li>
            </ul>

            <section class="p-lg-2 d-flex flex-column form_btn">
                <div class="row">
                    <div class="col-6">
                        <button mat-raised-button (click)="open_campaign_form()" class="comp_formm" color="{{contact_form_campaign?.submite_button_color}}">{{contact_form_campaign?.name}}</button>
                    </div>
                </div>
            </section>

            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse mobile-menu" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngFor="let menu_page of all_pages; let indx = index" id="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        
                        <a class="nav-link js-scroll-trigger" onclick="hideboth()" [ngStyle]="{'color': campaign.menu_color ? campaign.menu_color : '#fff' }" [href]="menu_page?.menu_value"
                            id="{{menu == menu_page?.menu_value ? 'showactivemenu' : '' }}">
                            
                            <span style="display:table-cell;vertical-align: middle;">
                                <ng-container *ngIf="menu_page?.content_type =='Infographic'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Infographic">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Infographic>
                                        <i class="fa fa-info-circle" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Image'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Image">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Image>
                                        <i class="fa fa-picture-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Landing Page'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Landing_Page">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Landing_Page>
                                        <i class="fa fa-globe" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='video'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else video">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #video>
                                        <i class="fa fa-file-video-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Ebook'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Ebook">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Ebook>
                                        <i class="fa fa-book" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Case Study'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Case_Study">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Case_Study>
                                        <i class="fa fa-eercast" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='White Paper'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else White_Paper">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #White_Paper>
                                        <i class="fa fa-newspaper-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Article'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Article">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Article>
                                        <i class="fa fa-fax" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>
                            </span>
                            <span style="display:table-cell;vertical-align: middle;padding-left: 10px;">{{menu_page?.manu_title}}</span>
                        </a>
                    </li>
                </ul>
                
                <section class="p-lg-2 d-flex flex-column form_btn_menu">
                    <div class="row">
                        <div class="col-6">
                            <button mat-raised-button (click)="open_campaign_form()" class="comp_form" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">{{contact_form_campaign?.name}}</button>
                        </div>
                    </div>
                </section>
            </div>

            <div class="collapse navbar-collapse desktop-menu" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngFor="let menu_page of all_pages; let indx = index" id="nav-item">
                        <a class="nav-link js-scroll-trigger" onclick="hideboth()" [href]="menu_page?.menu_value"
                            [ngStyle]="{'color': campaign.menu_color ? campaign.menu_color : '#fff'  }" id="{{menu == menu_page?.menu_value ? 'showactivemenu' : '' }}">

                            <span style="display:table-cell;vertical-align: middle;">
                                <ng-container *ngIf="menu_page.content_type =='Infographic'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Infographic">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Infographic>
                                        <i class="fa fa-info-circle" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Image'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Image">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Image>
                                        <i class="fa fa-picture-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='video'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else video">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #video>
                                        <i class="fa fa-file-video-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Landing Page'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Landing_Page">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Landing_Page>
                                        <i class="fa fa-globe" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Ebook'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Ebook">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Ebook>
                                        <i class="fa fa-book" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Case Study'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Case_Study">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Case_Study>
                                        <i class="fa fa-eercast" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='White Paper'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else White_Paper">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #White_Paper>
                                        <i class="fa fa-newspaper-o" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>

                                <ng-container *ngIf="menu_page.content_type =='Article'">
                                    <ng-container *ngIf="menu_page.menu_icon !=''  && menu_page.menu_icon != NULL; else Article">
                                        <i class="{{menu_page.menu_icon}}" id="icon_mohit"></i>
                                    </ng-container>
                                    <ng-template #Article>
                                        <i class="fa fa-fax" id="icon_mohit"></i>
                                    </ng-template>
                                </ng-container>
                            </span>
                            <span style="display:table-cell;vertical-align: middle;padding-left: 10px;">{{menu_page?.manu_title}}</span>
                        </a>
                    </li>
                </ul>

                <section class="p-lg-2 d-flex flex-column form_btn_menu">
                    <div class="row">
                        <div class="col-6">
                            <button mat-raised-button (click)="open_campaign_form()" class="comp_form">{{contact_form_campaign?.name}}</button>
                        </div>
                    </div>
                </section>
            </div>
        </nav>

        <div class="container-fluid p-0">
            <ng-container *ngIf="this.current_page.banner != ''  && this.current_page.banner != 0 && current_page.banner_position === 'top' && banner_data != ''">
                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-indicators" *ngIf="banner_data?.length > 1 && banner_data != ''">
                        <ng-container *ngFor="let banner of banner_data; let a = index">
                            <h1>{{i}}</h1>
                            <button type="button" [class]="(a === 0) ? 'active' : ''" data-bs-target="#carouselExampleDark" [attr.data-bs-slide-to]="a" class="indegater"
                                [aria-current]="(i === 0) ? 'true' : 'false'" aria-label="Slide 1"></button>
                        </ng-container>
                    </div>

                    <div class="carousel-inner">
                        <div class="carousel-item" data-bs-interval="10000" *ngFor="let banner of banner_data; let i=index" [class]="(i === 0) ? 'active' : ''">
                            <img src="{{assets_url}}{{banner.banner_image}}" class="d-block w-100 banner_image" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                                <a target="_blank" href="{{banner.banner_url}}" (click)="banner_report(banner.form_id)">
                                    <h3 class="banner_title">{{banner.banner_title}}</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="banner_data?.length > 1 && banner_data != ''">
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev"></button>
                        
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next"></button>
                    </ng-container>
                </div>
            </ng-container>
            
            <section class="resume-section p-3 dynamic_page" id="datachange0" style="width: 98%; margin: auto;">
                <ng-container *ngIf="current_page.page_type === 'Image'">
                    <ng-container *ngIf="current_page.page_image != ''; else noimage">
                        <img class="img-fluid  mx-auto mb-2 imagebanner" src="{{assets_url}}{{current_page.page_image}}" alt="">
                    </ng-container>
                    <ng-template #noimage>
                        <h3>Image Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'Editor'" class="dynamic_page_container">
                    <ng-container *ngIf="current_page.page_editor != ''; else noeditor">
                        <p class="">
                            <span [innerHTML]="sanitized_html" class="dynamic_page_span"></span>
                        </p>
                    </ng-container>
                    <ng-template #noeditor>
                        <h3>Data Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'PDF'">
                    <ng-container *ngIf="current_page.page_pdf != ''; else nopdf">
                        <div *ngIf="loaderview">
                            <div class="loader-new"></div>
                            <h3 class="loader-text">Downloading PDF Please Wait...</h3>
                        </div>

                        <div class="pdf-container">
                            <pdf-viewer [src]="pdf_url+current_page.page_pdf" [show-all]="true" [autoresize]="true" [original-size]='true' [fit-to-page]='true' [render-text]='false'
                                (after-load-complete)="callBackFn($event)">
                            </pdf-viewer>
                        </div>
                    </ng-container>
                    <ng-template #nopdf>
                        <h3>PDF Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'Audio'">
                    <ng-container *ngIf="current_page.page_audio != ''; else noaudio">
                        <audio class="audio-controls" controls>
                            <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/ogg">
                            <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/mpeg">
                        </audio>
                    </ng-container>
                    <ng-template #noaudio>
                        <h3>Audio Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'url'">
                    <ng-container *ngIf="current_page.page_url != ''; else nopageurl">
                        <iframe width="100%" height="750" [src]="embeded_page_url" frameborder="0" allow=""></iframe>
                    </ng-container>
                    <ng-template #nopageurl>
                        <h3>Url Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'embeded_video_url'">
                    <ng-container *ngIf="current_page.page_embeded_video_url != ''; else noevideourl">
                        <iframe width="100%" style="min-height: 90vh;" [src]="embeded_video_url" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </ng-container>
                    <ng-template #noevideourl>
                        <h3>Embeded Video Url Not Found!</h3>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="current_page.page_type === 'Video'">
                    <ng-container *ngIf="current_page.page_video != ''; else novideo">
                        <video width="100%" height="540" controls autoplay>
                            <source src="{{assets_url}}{{current_page.page_video}}" type="video/mp4">
                            <source src="{{assets_url}}{{current_page.page_video}}" type="video/ogg">
                        </video>
                    </ng-container>
                    <ng-template #novideo>
                        <h3>Video Not Found!</h3>
                    </ng-template>
                </ng-container>
            </section>

            <ng-container *ngIf="this.current_page.banner != '' && this.current_page.banner != 0 && current_page.banner_position === 'bottom' && banner_data != ''">
                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-indicators" *ngIf="banner_data?.length > 1 && banner_data != ''">
                        <ng-container *ngFor="let banner of banner_data; let a = index">
                            <h1>{{i}}</h1>
                            <button type="button" [class]="(a === 0) ? 'active' : ''" data-bs-target="#carouselExampleDark" [attr.data-bs-slide-to]="a" class="indegater"
                                [aria-current]="(i === 0) ? 'true' : 'false'" aria-label="Slide 1"></button>
                        </ng-container>
                    </div>

                    <div class="carousel-inner">
                        <div class="carousel-item" data-bs-interval="10000" *ngFor="let banner of banner_data; let i=index" [class]="(i === 0) ? 'active' : ''">
                            <img src="{{assets_url}}{{banner.banner_image}}" class="d-block w-100 banner_image" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                                <a target="_blank" href="{{banner.banner_url}}" (click)="banner_report(banner.form_id)">
                                    <h3 class="banner_title">{{banner.banner_title}}</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="banner_data?.length > 1 && banner_data != ''">
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev"></button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next"></button>
                    </ng-container>
                </div>
            </ng-container>

            <section class="resume-section p-3 p-lg-5 d-flex flex-column" *ngIf="contact_form && contact_form != null && current_page && current_page != null &&  current_page.contact_form_view_type == 'modal'">
                <div class="row">
                    <div class="col-4">
                        <button mat-raised-button (click)="openDialog()" color="{{contact_form.submite_button_color}}">{{contact_form.name}}</button>
                    </div>
                </div>
            </section>

            <section class="resume-section p-3 p-lg-5 d-flex flex-column" *ngIf="contact_form && contact_form != null && current_page && current_page != null &&  current_page.contact_form_view_type == 'onpage'">

                <!-- <========================================== Simple Form Sectaion ============================================== -->
                <div *ngIf="form_type == 'simple_form'">
                    <div class="row {{this.form_type}}" style="margin-bottom: 70px;">
                        <div class="col-lg-3 {{this.form_position}}" style="padding: 0px;box-shadow: 0px 0px 5px #aaaaaa;border-radius: 7px;">
                            <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                                <mat-card class="example-card">
    
                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>
    
                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>
    
                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">
    
                                        <mat-input-container>
                                            <div class="form-field example-container"
                                                *ngFor="let form_field of contact_form_field; let i = index">
    
                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.first_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">
    
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                        {{contactFormValidationn.first_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.last_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                        {{contactFormValidationn.last_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.contact"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                        {{contactFormValidationn.contact_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.gender_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput [formControl]="contactForm.controls.email"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.email_error_msg != ''">
                                                        {{contactFormValidationn.email_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_error_msg != ''">
                                                        {{contactFormValidationn.company_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.address"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.address_error_msg != ''">
                                                        {{contactFormValidationn.address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.language_error_msg != ''">
                                                        {{contactFormValidationn.language_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                        {{contactFormValidationn.allow_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput
                                                        [formControl]="contactForm.controls.description"></textarea>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.description_error_msg != ''">
                                                        {{contactFormValidationn.description_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker"
                                                        [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                        {{contactFormValidationn.date_of_birth_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.marital_status_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.education"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.education_error_msg != ''">
                                                        {{contactFormValidationn.education_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.aadhaar_card_no"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                        {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.salary"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                        {{contactFormValidationn.salary_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.company_address"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                        {{contactFormValidationn.company_address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.job_title"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                        {{contactFormValidationn.job_title_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                    class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}" readonly
                                                                name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>
    
                                                        <input type="file" #fileInput id="uploadFile"
                                                            (change)="uploadFileEvt($event)" name="uploadFile"
                                                            accept="image/*" />
                                                    </div>
                                                </mat-form-field>
                                                <p class="mat-hind-wrapper"
                                                    *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                    </mat-hint>
                                                </p>
                                            </div>
    
                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button"
                                                    color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>
    
                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
                                            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button
                                                class="save_button" color="primary" disabled>Please wait...</button>
    
                                        </mat-input-container>
                                    </mat-card-content>
                                </mat-card>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- <========================================== Left Image Form Sectaion ============================================== -->
                <div *ngIf="form_type == 'left_image_form'">
                    <div class="row {{this.form_type}}" style="margin-bottom: 70px;">
                        <div class="col-lg-6 {{this.form_position}}" style="padding: 0px;box-shadow: 0px 0px 5px #aaaaaa;border-radius: 7px;">

                            <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                                <mat-card class="example-card">

                                    <div class="col-6" style="float: left;margin-left: -6px;">
                                        <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                    </div>

                                    <div class="col-6" style="float: right;margin-right: -6px;">

                                        <mat-card-header>
                                            <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                        </mat-card-header>

                                        <mat-card-header>
                                            <mat-card-title>
                                                <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                                <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                            </mat-card-title>
                                        </mat-card-header>

                                        <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                            <mat-input-container>
                                                <div class="form-field example-container"
                                                    *ngFor="let form_field of contact_form_field; let i = index">

                                                    <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.first_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                            {{contactFormValidationn.first_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.last_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                            {{contactFormValidationn.last_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.contact"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                            {{contactFormValidationn.contact_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group [formControl]="contactForm.controls.gender">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.gender_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="email" matInput
                                                            [formControl]="contactForm.controls.email"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.email_error_msg != ''">
                                                            {{contactFormValidationn.email_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_error_msg != ''">
                                                            {{contactFormValidationn.company_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.address"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.address_error_msg != ''">
                                                            {{contactFormValidationn.address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.language">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.language_error_msg != ''">
                                                            {{contactFormValidationn.language_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.allow">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                            {{contactFormValidationn.allow_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <textarea matInput
                                                            [formControl]="contactForm.controls.description"></textarea>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.description_error_msg != ''">
                                                            {{contactFormValidationn.description_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input readonly matInput [matDatepicker]="picker"
                                                            [formControl]="contactForm.controls.date_of_birth">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                            {{contactFormValidationn.date_of_birth_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group
                                                            [formControl]="contactForm.controls.marital_status">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.marital_status_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.education"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.education_error_msg != ''">
                                                            {{contactFormValidationn.education_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.aadhaar_card_no"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                            {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.salary"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                            {{contactFormValidationn.salary_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company_address"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                            {{contactFormValidationn.company_address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.job_title"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                            {{contactFormValidationn.job_title_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                        class="example-full-width mb-10 upload-file">
                                                        <div>
                                                            <mat-toolbar>
                                                                <input matInput placeholder="{{form_field.form_label}}"
                                                                    readonly name="name" />
                                                                <button type="button" mat-flat-button> Browse File </button>
                                                            </mat-toolbar>

                                                            <input type="file" #fileInput id="uploadFile"
                                                                (change)="uploadFileEvt($event)" name="uploadFile"
                                                                accept="image/*" />
                                                        </div>
                                                    </mat-form-field>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                        <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button"
                                                        color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                                </ng-container>

                                                <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button" color="{{submite_button_color}}">Submit</button>
                                                </ng-container>
                                                <button *ngIf="isButtonLoading == true" type="submit" class="save_button"
                                                    mat-raised-button color="primary" disabled>Please wait...</button>

                                            </mat-input-container>
                                        </mat-card-content>
                                    </div>
                                </mat-card>
                            </form>
                        </div>

                    </div>
                </div>

                <!-- <========================================== Right Image Form Sectaion ============================================== -->
                <div *ngIf="form_type == 'right_image_form'">
                    <div class="row {{this.form_type}}" style="margin-bottom: 70px;">
                        <div class="col-lg-6 {{this.form_position}}" style="padding: 0px;box-shadow: 0px 0px 5px #aaaaaa;border-radius: 7px;">

                            <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                                <mat-card class="example-card">

                                    <div class="col-6" style="float: left;margin-left: -6px;">

                                        <mat-card-header>
                                            <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                        </mat-card-header>

                                        <mat-card-header>
                                            <mat-card-title>
                                                <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                                <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                            </mat-card-title>
                                        </mat-card-header>

                                        <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                            <mat-input-container>
                                                <div class="form-field example-container"
                                                    *ngFor="let form_field of contact_form_field; let i = index">

                                                    <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.first_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                            {{contactFormValidationn.first_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.last_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                            {{contactFormValidationn.last_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.contact"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                            {{contactFormValidationn.contact_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group [formControl]="contactForm.controls.gender">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.gender_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="email" matInput
                                                            [formControl]="contactForm.controls.email"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.email_error_msg != ''">
                                                            {{contactFormValidationn.email_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_error_msg != ''">
                                                            {{contactFormValidationn.company_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.address"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.address_error_msg != ''">
                                                            {{contactFormValidationn.address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.language">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.language_error_msg != ''">
                                                            {{contactFormValidationn.language_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.allow">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                            {{contactFormValidationn.allow_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <textarea matInput
                                                            [formControl]="contactForm.controls.description"></textarea>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.description_error_msg != ''">
                                                            {{contactFormValidationn.description_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input readonly matInput [matDatepicker]="picker"
                                                            [formControl]="contactForm.controls.date_of_birth">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                            {{contactFormValidationn.date_of_birth_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group
                                                            [formControl]="contactForm.controls.marital_status">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.marital_status_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.education"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.education_error_msg != ''">
                                                            {{contactFormValidationn.education_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.aadhaar_card_no"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                            {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.salary"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                            {{contactFormValidationn.salary_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company_address"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                            {{contactFormValidationn.company_address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.job_title"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                            {{contactFormValidationn.job_title_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                        class="example-full-width mb-10 upload-file">
                                                        <div>
                                                            <mat-toolbar>
                                                                <input matInput placeholder="{{form_field.form_label}}"
                                                                    readonly name="name" />
                                                                <button type="button" mat-flat-button> Browse File </button>
                                                            </mat-toolbar>

                                                            <input type="file" #fileInput id="uploadFile"
                                                                (change)="uploadFileEvt($event)" name="uploadFile"
                                                                accept="image/*" />
                                                        </div>
                                                    </mat-form-field>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                        <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button"
                                                        color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                                </ng-container>

                                                <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button" color="{{submite_button_color}}">Submit</button>
                                                </ng-container>
                                                <button *ngIf="isButtonLoading == true" type="submit" class="save_button"
                                                    mat-raised-button color="primary" disabled>Please wait...</button>

                                            </mat-input-container>
                                        </mat-card-content>
                                    </div>
                                    <div class="col-6" style="float: right;margin-right: -6px;">
                                        <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                    </div>
                                </mat-card>
                            </form>
                        </div>

                    </div>
                </div>

                 <!-- <========================================== Top Image Form Sectaion ============================================== -->
                <div *ngIf="form_type == 'top_image_form'">
                    <div class="row {{this.form_type}}" style="margin-bottom: 70px;">
                        <div class="col-lg-4 {{this.form_position}}" style="padding: 0px;box-shadow: 0px 0px 5px #aaaaaa;border-radius: 7px;">

                            <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                                <mat-card class="example-card">

                                        <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                        <mat-card-header>
                                            <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                        </mat-card-header>

                                        <mat-card-header>
                                            <mat-card-title>
                                                <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                                <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                            </mat-card-title>
                                        </mat-card-header>

                                        <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                            <mat-input-container>
                                                <div class="form-field example-container"
                                                    *ngFor="let form_field of contact_form_field; let i = index">

                                                    <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.first_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                            {{contactFormValidationn.first_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.last_name"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                            {{contactFormValidationn.last_name_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.contact"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                            {{contactFormValidationn.contact_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group [formControl]="contactForm.controls.gender">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.gender_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="email" matInput
                                                            [formControl]="contactForm.controls.email"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.email_error_msg != ''">
                                                            {{contactFormValidationn.email_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_error_msg != ''">
                                                            {{contactFormValidationn.company_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.address"
                                                            placeholder="Enter {{form_field.form_label}}"
                                                            [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.address_error_msg != ''">
                                                            {{contactFormValidationn.address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.language">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.language_error_msg != ''">
                                                            {{contactFormValidationn.language_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-select [formControl]="contactForm.controls.allow">
                                                            <mat-option *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-option>
                                                        </mat-select>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                            {{contactFormValidationn.allow_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <textarea matInput
                                                            [formControl]="contactForm.controls.description"></textarea>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.description_error_msg != ''">
                                                            {{contactFormValidationn.description_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                        class="example-full-width mb-10 " appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input readonly matInput [matDatepicker]="picker"
                                                            [formControl]="contactForm.controls.date_of_birth">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                            {{contactFormValidationn.date_of_birth_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                        class="example-full-width mb-10 ">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <mat-radio-group
                                                            [formControl]="contactForm.controls.marital_status">
                                                            <mat-radio-button
                                                                *ngFor="let option of form_field.options.split(',')"
                                                                value="{{option}}">{{option}}</mat-radio-button>
                                                        </mat-radio-group>
                                                        <p class="mat-hind-wrapper"
                                                            *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                            <mat-hint class="error">
                                                                {{contactFormValidationn.marital_status_error_msg}}
                                                            </mat-hint>
                                                        </p>
                                                    </div>

                                                    <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.education"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.education_error_msg != ''">
                                                            {{contactFormValidationn.education_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.aadhaar_card_no"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                            {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="number" matInput
                                                            [formControl]="contactForm.controls.salary"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                            {{contactFormValidationn.salary_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.company_address"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                            {{contactFormValidationn.company_address_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                        class="example-full-width mb-10" appearance="fill">
                                                        <mat-label>{{form_field.form_label}}</mat-label>
                                                        <input type="text" matInput
                                                            [formControl]="contactForm.controls.job_title"
                                                            placeholder="Enter {{form_field.form_label}}">
                                                        <mat-hint class="error"
                                                            *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                            {{contactFormValidationn.job_title_error_msg}}
                                                        </mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                        class="example-full-width mb-10 upload-file">
                                                        <div>
                                                            <mat-toolbar>
                                                                <input matInput placeholder="{{form_field.form_label}}"
                                                                    readonly name="name" />
                                                                <button type="button" mat-flat-button> Browse File </button>
                                                            </mat-toolbar>

                                                            <input type="file" #fileInput id="uploadFile"
                                                                (change)="uploadFileEvt($event)" name="uploadFile"
                                                                accept="image/*" />
                                                        </div>
                                                    </mat-form-field>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                        <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button"
                                                        color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                                </ng-container>

                                                <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                    <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                        class="save_button" color="{{submite_button_color}}">Submit</button>
                                                </ng-container>
                                                <button *ngIf="isButtonLoading == true" type="submit" class="save_button"
                                                    mat-raised-button color="primary" disabled>Please wait...</button>

                                            </mat-input-container>
                                        </mat-card-content>
                                </mat-card>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="navigation-controller">
            <div class="nav-control">
                <div class="custom-nav-link prev-link" *ngIf="previous_menu != ''">
                    <div class="arrowpopup1">
                        <span class="tooltiptext1" id="tooltipdemo1">
                            <p><i class="fa fa-arrow-left" aria-hidden="true"></i> Previous</p>
                            <img *ngIf="current_page.previous_html_template" src="{{assets_url}}{{current_page.previous_html_template}}" class="img-fluid"><br>
                            <strong *ngIf="current_page.previoustitle">{{current_page.previoustitle}}</strong>
                            <p *ngIf="current_page.previous_text">{{current_page.previous_text}}</p>
                        </span>
                    </div>
                </div>

                <div class="custom-nav-link prev-link">
                    <a *ngIf="previous_menu != ''" href="{{previous_menu}}" onmouseover="myFunctionpre()" onmouseleave="myFunctionhidepre()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Previous </a>
                </div>

                <div class="custom-nav-link prev-link" *ngIf="next_menu != ''">
                    <div class="arrowpopup">
                        <span class="tooltiptext" id="tooltipdemo">
                            <p>Next <i class="fa fa-arrow-right" aria-hidden="true"></i> </p>
                            <img *ngIf="current_page.next_html_template" src="{{assets_url}}{{current_page.next_html_template}}" class="img-fluid"><br>
                            <strong *ngIf="current_page.next_title">{{current_page.next_title}}</strong>
                            <p *ngIf="current_page.nexttext">{{current_page.nexttext}}</p>
                        </span>
                    </div>
                </div>

                <div id="{{total_page}} - {{next_page}}" class="custom-nav-link next-link">
                    <a *ngIf="next_menu != ''" href="{{next_menu}}" onmouseover="myFunction()" onmouseleave="myFunctionhidenext()">Next <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
                </div>
            </div>

            <footer>

            </footer>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="campaign?.front_template == 3">
    <header id="header" class="header-area header-area--default">
        <div class="header-bottom-wrap header-sticky">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="header default-menu-style position-relative">
                            
                            <div class="header__logo">
                                <a href="{{newstr}}">
                                    <img src="{{assets_url}}{{campaign?.logo}}" width="160" height="48" class="img-fluid" alt="">
                                </a>
                            </div>

                            <div class="header-midle-box">
                                <div class="header-bottom-wrap d-md-block d-none">
                                    <div class="header-bottom-inner">
                                        <div class="header-bottom-left-wrap">
                                            <div class="header__navigation d-none d-xl-block">
                                                <nav class="navigation-menu primary--menu">
                                                    <ul>
                                                        <ng-container *ngFor="let menudata of all_pages; let key=index;">
                                                            <ng-container *ngIf="menudata.children && menudata.children.length > 0">
                                                                <li class="has-children has-children--multilevel-submenu">
                                                                    <a [href]="menudata.parents?.menu_value"><span>{{menudata.parents.manu_title}}</span></a>
                                                            
                                                                    <ul class="submenu" *ngIf="key == '0'">
                                                                        <li *ngFor="let childrenmenu of menudata.children">
                                                                            <a [href]="childrenmenu?.menu_value"><span>{{childrenmenu.manu_title}}</span></a>
                                                                        </li>
                                                                    </ul>

                                                                    <ul class="megamenu megamenu--mega" *ngIf="key == '1'">
                                                                        <li *ngFor="let childrenmenu of menudata.children">
                                                                            <h2 class="page-list-title">{{childrenmenu.manu_title}}</h2>
                                                                            <ul *ngIf="childrenmenu.subchildren && childrenmenu.subchildren.length > 0">
                                                                                <li *ngFor="let subchildrenmenu of childrenmenu.subchildren">
                                                                                    <a [href]="subchildrenmenu?.menu_value"><span>{{subchildrenmenu.manu_title}}</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ng-container>
                                                            <ng-container *ngIf="!menudata.children || menudata.children.length == 0">
                                                                <li *ngIf="menudata.parents.page_type != 'website_url'">
                                                                    <a [href]="menudata.parents?.menu_value"><span>{{menudata.parents.manu_title}}</span></a>
                                                                </li>
                                                                <li *ngIf="menudata.parents.page_type == 'website_url'">
                                                                    <a href="{{menudata.parents?.website_url}}" target="_blank"><span>{{menudata.parents.manu_title}}</span></a>
                                                                </li>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="header-right-box">
                                <div class="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger">
                                    <i></i>
                                </div>

                                <div class="hidden-icons-menu d-block d-md-none" id="hidden-icon-trigger">
                                    <a href="javascript:void(0)">
                                        <i class="far fa-ellipsis-h-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <main id="main">
        <section class="resume-section dynamic_page" id="datachange0" style="width: 100%; margin: auto;">
            <ng-container *ngIf="current_page.page_type === 'Video'">
                <ng-container *ngIf="current_page.page_video != ''; else novideo">
                    <video width="100%" height="540" controls autoplay>
                        <source src="{{assets_url}}{{current_page.page_video}}" type="video/mp4">
                        <source src="{{assets_url}}{{current_page.page_video}}" type="video/ogg">
                    </video>
                </ng-container>
                <ng-template #novideo>
                    <h2>Video Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Editor'" class="dynamic_page_container">
                <ng-container *ngIf="current_page.page_editor != ''; else noeditor">
                    <p class="">
                        <span [innerHTML]="sanitized_html" class="dynamic_page_span"></span>
                    </p>
                </ng-container>
                <ng-template #noeditor>
                    <h2>HTML Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'PDF'">
                <ng-container *ngIf="current_page.page_pdf != ''; else nopdf">
                    <div *ngIf="loaderview">
                        <div class="loader-new"></div>
                        <h3 class="loader-text">Downloading PDF Please Wait...</h3>
                    </div>

                    <div class="pdf-container">
                        <pdf-viewer [src]="pdf_url+current_page.page_pdf" [show-all]="true" [autoresize]="true"
                            [original-size]='true' [fit-to-page]='true' [render-text]='false'
                            (after-load-complete)="callBackFn($event)">
                        </pdf-viewer>
                    </div>
                </ng-container>
                <ng-template #nopdf>
                    <h2>PDF Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Image'">
                <ng-container *ngIf="current_page.page_image != ''; else noimage">
                    <img class="img-fluid  mx-auto mb-2 imagebanner" src="{{assets_url}}{{current_page.page_image}}"
                        alt="" style="width: 100%;">
                </ng-container>
                <ng-template #noimage>
                    <h2>Image Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'Audio'">
                <ng-container *ngIf="current_page.page_audio != ''; else noaudio">
                    <audio class="audio-controls" controls>
                        <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/ogg">
                        <source src="{{assets_url}}{{current_page.page_audio}}" type="audio/mpeg">
                    </audio>
                </ng-container>
                <ng-template #noaudio>
                    <h2>Audio Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'url'">
                <ng-container *ngIf="current_page.page_url != ''; else nopageurl">
                    <iframe width="100%" height="750" [src]="embeded_page_url" frameborder="0" allow=""></iframe>
                </ng-container>
                <ng-template #nopageurl>
                    <h2>Url Not Found!</h2>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="current_page.page_type === 'embeded_video_url'">
                <ng-container *ngIf="current_page.page_embeded_video_url != ''; else noevideourl">
                    <iframe width="100%" style="min-height: 90vh;" [src]="embeded_video_url"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </ng-container>
                <ng-template #noevideourl>
                    <h2>Embeded Video Url Not Found!</h2>
                </ng-template>
            </ng-container>
        </section>

        <section class="resume-section p-3 p-lg-5 d-flex flex-column" *ngIf="contact_form && contact_form != null && current_page && current_page != null &&  current_page.contact_form_view_type == 'onpage'">

            <!-- <========================================== Simple Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'simple_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">
                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <mat-card-header>
                                    <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                </mat-card-header>

                                <mat-card-header>
                                    <mat-card-title>
                                        <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                        <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                    </mat-card-title>
                                </mat-card-header>

                                <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                    <mat-input-container>
                                        <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">

                                            <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}" 
                                                [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>
                                                
                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.language">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-select [formControl]="contactForm.controls.allow">
                                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-option>
                                                </mat-select>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>

                                                <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-radio-button>
                                                </mat-radio-group>

                                                <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                    <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                </p>
                                            </div>

                                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.education" placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no" placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="number" matInput [formControl]="contactForm.controls.salary" placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.company_address" placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                <mat-label>{{form_field.form_label}}</mat-label>
                                                <input type="text" matInput [formControl]="contactForm.controls.job_title" placeholder="Enter {{form_field.form_label}}">
                                                
                                                <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                <div>
                                                    <mat-toolbar>
                                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                        <button type="button" mat-flat-button> Browse File </button>
                                                    </mat-toolbar>

                                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                </div>
                                            </mat-form-field>
                                            <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                            </p>
                                        </div>

                                        <ng-container *ngIf="contact_form.submit_button_name != ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                        </ng-container>

                                        <ng-container *ngIf="contact_form.submit_button_name == ''">
                                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                        </ng-container>

                                        <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>

                                    </mat-input-container>
                                </mat-card-content>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <========================================== Left Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'left_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">

                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <div class="col-6" style="float: left;padding-left: 0px;">
                                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                </div>

                                <div class="col-6" style="float: right;padding-right: 0px;">

                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>

                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                        <mat-input-container>
                                            <div class="form-field example-container" *ngFor="let form_field of contact_form_field; let i = index">
    
                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.first_name" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">
    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.first_name_error_msg != ''">{{contactFormValidationn.first_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.last_name" placeholder="Enter {{form_field.form_label}}" 
                                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.last_name_error_msg != ''">{{contactFormValidationn.last_name_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.contact" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.contact_error_msg != ''">{{contactFormValidationn.contact_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.gender_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput [formControl]="contactForm.controls.email" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.email_error_msg != ''">{{contactFormValidationn.email_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_error_msg != ''">{{contactFormValidationn.company_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.address" placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.address_error_msg != ''">{{contactFormValidationn.address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.language_error_msg != ''">{{contactFormValidationn.language_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.allow_error_msg != ''">{{contactFormValidationn.allow_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'description'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.description_error_msg != ''">{{contactFormValidationn.description_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'" class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker" [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">{{contactFormValidationn.date_of_birth_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''" class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button *ngFor="let option of form_field.options.split(',')" value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
    
                                                    <p class="mat-hind-wrapper" *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">{{contactFormValidationn.marital_status_error_msg}}</mat-hint>
                                                    </p>
                                                </div>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.education" placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.education_error_msg != ''">{{contactFormValidationn.education_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no" placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">{{contactFormValidationn.aadhaar_card_no_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput [formControl]="contactForm.controls.salary" placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.salary_error_msg != ''">{{contactFormValidationn.salary_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.company_address" placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.company_address_error_msg != ''">{{contactFormValidationn.company_address_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput [formControl]="contactForm.controls.job_title" placeholder="Enter {{form_field.form_label}}">
                                                    
                                                    <mat-hint class="error" *ngIf="contactFormValidationn.job_title_error_msg != ''">{{contactFormValidationn.job_title_error_msg}}</mat-hint>
                                                </mat-form-field>
    
                                                <mat-form-field *ngIf="form_field.form_name == 'image'" class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>
    
                                                        <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile" accept="image/*" />
                                                    </div>
                                                </mat-form-field>
                                                <p class="mat-hind-wrapper" *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}</mat-hint>
                                                </p>
                                            </div>
    
                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>
    
                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
    
                                            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button class="save_button" color="primary" disabled>Please wait...</button>
    
                                        </mat-input-container>
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <========================================== Right Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'right_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}">

                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                <div class="col-6" style="float: left;padding-left: 0px;">

                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>

                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                        <mat-input-container>
                                            <div class="form-field example-container"
                                                *ngFor="let form_field of contact_form_field; let i = index">

                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.first_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                        {{contactFormValidationn.first_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.last_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                        {{contactFormValidationn.last_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.contact"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                        {{contactFormValidationn.contact_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.gender_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput
                                                        [formControl]="contactForm.controls.email"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.email_error_msg != ''">
                                                        {{contactFormValidationn.email_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.company"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_error_msg != ''">
                                                        {{contactFormValidationn.company_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.address"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.address_error_msg != ''">
                                                        {{contactFormValidationn.address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.language_error_msg != ''">
                                                        {{contactFormValidationn.language_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                        {{contactFormValidationn.allow_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput
                                                        [formControl]="contactForm.controls.description"></textarea>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.description_error_msg != ''">
                                                        {{contactFormValidationn.description_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker"
                                                        [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                        {{contactFormValidationn.date_of_birth_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group
                                                        [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.marital_status_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.education"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.education_error_msg != ''">
                                                        {{contactFormValidationn.education_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.aadhaar_card_no"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                        {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.salary"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                        {{contactFormValidationn.salary_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.company_address"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                        {{contactFormValidationn.company_address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.job_title"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                        {{contactFormValidationn.job_title_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                    class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}"
                                                                readonly name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>

                                                        <input type="file" #fileInput id="uploadFile"
                                                            (change)="uploadFileEvt($event)" name="uploadFile"
                                                            accept="image/*" />
                                                    </div>
                                                </mat-form-field>
                                                <p class="mat-hind-wrapper"
                                                    *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                    </mat-hint>
                                                </p>
                                            </div>

                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button"
                                                    color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>

                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
                                            <button *ngIf="isButtonLoading == true" type="submit" class="save_button"
                                                mat-raised-button color="primary" disabled>Please wait...</button>

                                        </mat-input-container>
                                    </mat-card-content>
                                </div>
                                <div class="col-6" style="float: right;padding-right: 0px;">
                                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                </div>
                            </mat-card>
                        </form>
                    </div>

                </div>
            </div>

            <!-- <========================================== Top Image Form Sectaion ============================================== -->
            <div *ngIf="form_type == 'top_image_form'">
                <div class="row {{this.form_type}}" style="margin-left: 0px;">
                    <div class="{{this.form_position}}" style="width: 25%;">

                        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <mat-card class="example-card">

                                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                                    <mat-card-header>
                                        <mat-card-title class="form_name">{{contact_form.name}}</mat-card-title>
                                    </mat-card-header>

                                    <mat-card-header>
                                        <mat-card-title>
                                            <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                                            <h3 class="form_header">{{contact_form?.form_header}}</h3>
                                        </mat-card-title>
                                    </mat-card-header>

                                    <mat-card-content *ngIf="contact_form_field && contact_form_field != null">

                                        <mat-input-container>
                                            <div class="form-field example-container"
                                                *ngFor="let form_field of contact_form_field; let i = index">

                                                <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.first_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.first_name_error_msg != ''">
                                                        {{contactFormValidationn.first_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'last_name'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.last_name"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.last_name_error_msg != ''">
                                                        {{contactFormValidationn.last_name_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'contact'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.contact"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.contact_error_msg != ''">
                                                        {{contactFormValidationn.contact_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group [formControl]="contactForm.controls.gender">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.gender_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.gender_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <mat-form-field *ngIf="form_field.form_name == 'email'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="email" matInput
                                                        [formControl]="contactForm.controls.email"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.email_error_msg != ''">
                                                        {{contactFormValidationn.email_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'company'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.company"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_error_msg != ''">
                                                        {{contactFormValidationn.company_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.address"
                                                        placeholder="Enter {{form_field.form_label}}"
                                                        [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.address_error_msg != ''">
                                                        {{contactFormValidationn.address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'language'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.language">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.language_error_msg != ''">
                                                        {{contactFormValidationn.language_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'allow'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-select [formControl]="contactForm.controls.allow">
                                                        <mat-option *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.allow_error_msg != ''">
                                                        {{contactFormValidationn.allow_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'description'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <textarea matInput
                                                        [formControl]="contactForm.controls.description"></textarea>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.description_error_msg != ''">
                                                        {{contactFormValidationn.description_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                                    class="example-full-width mb-10 " appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input readonly matInput [matDatepicker]="picker"
                                                        [formControl]="contactForm.controls.date_of_birth">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.date_of_birth_error_msg != ''">
                                                        {{contactFormValidationn.date_of_birth_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                                    class="example-full-width mb-10 ">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <mat-radio-group
                                                        [formControl]="contactForm.controls.marital_status">
                                                        <mat-radio-button
                                                            *ngFor="let option of form_field.options.split(',')"
                                                            value="{{option}}">{{option}}</mat-radio-button>
                                                    </mat-radio-group>
                                                    <p class="mat-hind-wrapper"
                                                        *ngIf="contactFormValidationn.marital_status_error_msg != ''">
                                                        <mat-hint class="error">
                                                            {{contactFormValidationn.marital_status_error_msg}}
                                                        </mat-hint>
                                                    </p>
                                                </div>

                                                <mat-form-field *ngIf="form_field.form_name == 'education'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.education"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.education_error_msg != ''">
                                                        {{contactFormValidationn.education_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.aadhaar_card_no"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.aadhaar_card_no_error_msg != ''">
                                                        {{contactFormValidationn.aadhaar_card_no_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'salary'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="number" matInput
                                                        [formControl]="contactForm.controls.salary"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.salary_error_msg != ''">
                                                        {{contactFormValidationn.salary_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.company_address"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.company_address_error_msg != ''">
                                                        {{contactFormValidationn.company_address_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'job_title'"
                                                    class="example-full-width mb-10" appearance="fill">
                                                    <mat-label>{{form_field.form_label}}</mat-label>
                                                    <input type="text" matInput
                                                        [formControl]="contactForm.controls.job_title"
                                                        placeholder="Enter {{form_field.form_label}}">
                                                    <mat-hint class="error"
                                                        *ngIf="contactFormValidationn.job_title_error_msg != ''">
                                                        {{contactFormValidationn.job_title_error_msg}}
                                                    </mat-hint>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="form_field.form_name == 'image'"
                                                    class="example-full-width mb-10 upload-file">
                                                    <div>
                                                        <mat-toolbar>
                                                            <input matInput placeholder="{{form_field.form_label}}"
                                                                readonly name="name" />
                                                            <button type="button" mat-flat-button> Browse File </button>
                                                        </mat-toolbar>

                                                        <input type="file" #fileInput id="uploadFile"
                                                            (change)="uploadFileEvt($event)" name="uploadFile"
                                                            accept="image/*" />
                                                    </div>
                                                </mat-form-field>
                                                <p class="mat-hind-wrapper"
                                                    *ngIf="form_field.form_name == 'image' && contactFormValidationn.image_error_msg != ''">
                                                    <mat-hint class="error"> {{contactFormValidationn.image_error_msg}}
                                                    </mat-hint>
                                                </p>
                                            </div>

                                            <ng-container *ngIf="contact_form.submit_button_name != ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button"
                                                    color="{{submite_button_color}}">{{contact_form.submit_button_name}}</button>
                                            </ng-container>

                                            <ng-container *ngIf="contact_form.submit_button_name == ''">
                                                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                                    class="save_button" color="{{submite_button_color}}">Submit</button>
                                            </ng-container>
                                            <button *ngIf="isButtonLoading == true" type="submit" class="save_button"
                                                mat-raised-button color="primary" disabled>Please wait...</button>

                                        </mat-input-container>
                                    </mat-card-content>
                            </mat-card>
                        </form>
                    </div>
                </div>
            </div>

        </section>
        <!-- ON Page Form End -->
    </main>

    <!--============================================== Footer Section ===========================================-->
    <footer id="footer">
        <div class="footer-area-wrapper black-bg" *ngIf="this.footer_page.pageeditor != ''; else nodynamic_footer">
            <span [innerHTML]="sanitized_footer"></span>
        </div>
        <ng-template #nodynamic_footer>
            <h3>Footer HTML Not Found!</h3>
        </ng-template>

        <div class="mobile-menu-overlay" id="mobile-menu-overlay">
            <div class="mobile-menu-overlay__inner">
                <div class="mobile-menu-overlay__header">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-md-6 col-8">
                                <div class="logo">
                                    <a href="{{newstr}}">
                                        <img src="{{assets_url}}{{campaign?.logo}}" class="img-fluid" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-6 col-4" style="padding: 0;">
                                <div class="mobile-menu-content text-end">
                                    <span class="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-menu-overlay__body">
                    <nav class="offcanvas-navigation">
                        <ul>
                            <ng-container *ngFor="let menudata of all_pages; let key=index;">
                                <ng-container *ngIf="menudata.children && menudata.children.length > 0">
                                    <li class="has-children">
                                        <a [href]="menudata.parents?.menu_value"><span>{{menudata.parents.manu_title}}</span></a>
                            
                                        <ul class="submenu" *ngIf="key == '0'">
                                            <li *ngFor="let childrenmenu of menudata.children">
                                                <a [href]="childrenmenu?.menu_value"><span>{{childrenmenu.manu_title}}</span></a>
                                            </li>
                                        </ul>
    
                                        <ul class="sub-menu" *ngIf="key == '1'">
                                            <li class="has-children" *ngFor="let childrenmenu of menudata.children">
                                                <a href="">{{childrenmenu.manu_title}}</a>
                                                <ul class="sub-menu" *ngIf="childrenmenu.subchildren && childrenmenu.subchildren.length > 0">
                                                    <li *ngFor="let subchildrenmenu of childrenmenu.subchildren">
                                                        <a [href]="subchildrenmenu?.menu_value"><span>{{subchildrenmenu.manu_title}}</span></a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
    
                                <ng-container *ngIf="!menudata.children || menudata.children.length == 0">
                                    <li *ngIf="menudata.parents.page_type != 'website_url'">
                                        <a [href]="menudata.parents?.menu_value"><span>{{menudata.parents.manu_title}}</span></a>
                                    </li>
                                    <li *ngIf="menudata.parents.page_type == 'website_url'">
                                        <a href="{{menudata.parents?.website_url}}" target="_blank"><span>{{menudata.parents.manu_title}}</span></a>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </footer>
    <!--============================================== Footer Section ===========================================-->

</ng-container>
import { Component, OnInit, ViewChild, ElementRef, Inject, PipeTransform, Pipe , HostListener,Renderer2} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { RestService } from '../../rest.service';
import { DomSanitizer, SafeResourceUrl, Title, BrowserModule, Meta} from '@angular/platform-browser';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactformdialogComponent } from '../contactformdialog/contactformdialog.component';
import { CampaigncontactformdialogComponent } from '../campaigncontactformdialog/campaigncontactformdialog.component';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

    @Pipe({ name: 'safeHtml' })
    @ViewChild('fileInput') fileInput: ElementRef;
    fileAttr = 'Choose File';

    contactForm = new FormGroup({
        first_name: new FormControl(''),
        last_name: new FormControl(''),
        contact: new FormControl(''),
        gender: new FormControl(''),
        email: new FormControl(''),
        company: new FormControl(''),
        address: new FormControl(''),
        language: new FormControl(''),
        allow: new FormControl(''),
        description: new FormControl(''),
        date_of_birth: new FormControl(''),
        marital_status: new FormControl(''),
        education: new FormControl(''),
        aadhaar_card_no: new FormControl(''),
        salary: new FormControl(''),
        company_address: new FormControl(''),
        job_title: new FormControl(''),
        image: new FormControl(''),
    });

    public contactFormValidation = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        job_title:false,
        job_title_error_msg: '',
        image_error_msg: '',
    };

    public contactFormValidationnOriginal = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title: false,
        job_title_error_msg: '',
    };

    public contactFormValidationn = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title: false,
        job_title_error_msg: '',
    };

    public contactFormValidationcamp = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title : false,
        job_title_error_msg : '',
    };

    public EnquiryFormField = {
        form_type: '',
        name: '',
        email: '',
        subject: '',
        description: '',
        contact_number: '',
        department: '',
    };

    public pageuniqueId:any;
    public renderer: any;
    public submite_camp_btn: any;
    public time: any;
    public query_type: any;
    public user_email: any;
    public campaign_id: any;
    public campaign_id_send: any;
    public page_id_send: any;
    public BaseUrl: any = this.restApi.BaseUrl
    public assets_url: any = this.restApi.assets_url;
    public manu_icon_default: any = this.restApi.manu_icon_default;
    public pdf_url: any = this.restApi.pdf_url;
    public a: any;
    public campaign: any;
    public contact_form: any;
    public contact_form_field: any;
    public current_page: any = [];
    public all_pages: any = [];
    public responseData: any = [];
    public embeded_video_url: SafeResourceUrl;
    public embeded_page_url: SafeResourceUrl;
    public isLoading = true;
    public prev_page: any = -1;
    public next_page: any = 1;
    public total_page: any = 0;
    public isButtonLoading: boolean = false;
    public time_insert_time_id: any;
    public clock: any;
    public timerr: any = 0;
    public display: any;
    public form_position: any;
    public gmail_id: any;
    public gmail_idd: any;
    public email_id: any;
    public banner_data: any;
    public year: any;
    public user_type: any;
    public banner_image: any;
    public banner_title: any;
    public baner_url: any;
    public contact_form_campaign: any;
    public contact_form_field_campaign: any;
    public contact_form_button_campaign: any;
    public popup_form_btn: any;
    public submite_button_color: any;
    public newstr: any;
    public popupform_userdata: any;
    public empty: any = '';
    public menu: any;
    public previous_menu: any;
    public next_menu: any;
    public tmp_id: any;
    public email_template_id: any;
    public text_editer: any;
    public email_template: any;
    public sanitized_html: any;
    public sanitized_htmlcss: any;
    public sanitized_htmlxxx: any;
    public script:any;
    public xyz: any;
    public send_id :any;
    public send_idd : any;
    public sendd_id : any;
    public utm_source: any;
    public utm_sourcee: any;
    public utm_sourcee_id:any;
    public previousScroll :any;
    public last_hit:any;
    public scrollHeight:any;
    public clientHeight:any; 
    public scoll_hit = 0;
    public howmuchtime:any;
    public clientHeightt:any;
    public scrollHeightt:any;
    public domain:any;
    public Subdomain:any;
    public salt:any;
    public CheckSalt:any;
    public salt_id:any;
    public interval: any;
    loaderview: boolean = false;
    public form_type:any;
    public formwidth:any;
    public formheight:any;
    public form_width:any;
    public form_height:any;
    public formtype:any;
    public preview_url: any = this.restApi.preview_url;
    public campaign_form_id:any;
    public timer:any;
    public Storagestring:any;
    public utm_medium: any;
    public user_utm_medium:any;
    public utm_term: any;
    public user_utm_term: any;
    public sanitized_footer: any;
    public dynamic_footer = false;
    public footer_page: any;

    constructor(
        public renderer2: Renderer2,
        public restApi: RestService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private Cookie: CookieService,
        public meta: Meta,
        public title: Title,
        public sanitized: DomSanitizer,
        @Inject(DOCUMENT)
        private _document: Document
    ) {

        this.activatedRoute.pathFromRoot[1].url.subscribe(val => {

            this.Subdomain = location.origin;
            this.campaign_id = 'Home';
            this.menu = val[0]?.path;

            this.domain = this.Subdomain.split('https://')[1];
            this.Storagestring = this.domain;

            this.activatedRoute.queryParams.subscribe((params: Params) => {

                this.salt = params.salt;
                if (this.salt === undefined) {
                    this.CheckSalt = localStorage.getItem(this.Storagestring+'-Salt');
                    if (this.CheckSalt != '' && this.CheckSalt != null) {
                        this.salt_id    = this.CheckSalt;
                        this.user_type  = "Known";
                    } else {
                        this.salt_id    = '';
                        this.user_type  = "Unknown";
                    }
                } else {
                    this.salt_id    = this.salt;
                    this.user_type  = "Known";
                    localStorage.setItem(this.Storagestring+'-Salt', this.salt);
                }

                this.send_id = params.send_id;
                if (this.send_id === undefined) {
                    this.sendd_id = localStorage.getItem(this.Storagestring+'-send_id');
                    if (this.sendd_id != '' && this.sendd_id != null) {
                        this.send_idd = this.sendd_id;
                    } else {
                        this.send_idd = '';
                    }
                } else {
                    this.send_idd = this.send_id;
                    localStorage.setItem(this.Storagestring+'-send_id', this.send_id);
                }

                this.tmp_id = params.tmp_id;
                if (this.tmp_id === undefined) {
                    this.email_template = localStorage.getItem(this.Storagestring+'-EmailTemplate');
                    if (this.email_template != '' && this.email_template != null) {
                        this.email_template_id = this.email_template;
                    } else {
                        this.email_template_id = '';
                    }
                } else {
                    this.email_template_id = this.tmp_id;
                    localStorage.setItem(this.Storagestring+'-EmailTemplate', this.tmp_id);
                }

                this.email_id = params.email_id;
                this.get_campaign_detail();
                if (this.email_id === undefined) {
                    this.gmail_idd = localStorage.getItem('UserEmail');
                    if (this.gmail_idd != "" && this.gmail_idd != null) {
                        this.gmail_id = this.gmail_idd;
                        this.startTimer();
                    } else {  
                        this.gmail_id = '';
                        this.startTimer();
                    }
                } else {
                    this.gmail_id = this.email_id;
                    localStorage.setItem('UserEmail', this.email_id);
                    this.startTimer();
                }

                this.utm_source = params.utm_source;
                if (this.utm_source === undefined) {
                    this.utm_sourcee = localStorage.getItem(this.Storagestring+'-utm_source');
                    if (this.utm_sourcee != '' && this.utm_sourcee != null) {
                        this.utm_sourcee_id = this.utm_sourcee;
                    } else {
                        this.utm_sourcee_id = '';
                    }
                } else {
                    this.utm_sourcee_id = this.utm_source;
                    localStorage.setItem(this.Storagestring+'-utm_source', this.utm_source);
                }

                this.utm_medium = params.utm_medium;
                if (this.utm_medium === undefined) {
                    this.utm_medium = localStorage.getItem(this.Storagestring+'-utm_medium');
                    if (this.utm_medium != '' && this.utm_medium != null) {
                        this.user_utm_medium = this.utm_medium;
                    } else {
                        this.user_utm_medium = '';
                    }
                } else {
                    this.user_utm_medium = this.utm_medium;
                    localStorage.setItem(this.Storagestring+'-utm_medium', this.utm_medium);
                }

                this.utm_term = params.utm_term;
                if (this.utm_term === undefined) {
                    this.utm_term = localStorage.getItem(this.Storagestring+'-utm_term');
                    if (this.utm_term != '' && this.utm_term != null) {
                        this.user_utm_term = this.utm_term;
                    } else {
                        this.user_utm_term = '';
                    }
                } else {
                    this.user_utm_term = this.utm_term;
                    localStorage.setItem(this.Storagestring+'-utm_term', this.utm_term);
                } 
            })
        })
    }

    ngOnInit(): void {
        this.get_form_autofill(); 

        this.loaderview = true;
        this.startTimeloader();
    }

    get_form_autofill() {

        this.gmail_id = localStorage.getItem('UserEmail');
        if(this.gmail_id != '' && this.gmail_id != null || this.salt_id != '' && this.salt_id != null)
        this.restApi.postData({ apikey: this.restApi.API_KEY, gmail_id: this.gmail_id , salt : this.salt_id }, 'get_campaign_form_auto').subscribe(response2 => {
            
            if (response2.responseStatus == 1) {

                localStorage.setItem('popupform_userdata', JSON.stringify(response2.data));
                this.popupform_userdata = response2.data;

                localStorage.setItem(this.Storagestring+'-EmailTemplate', this.popupform_userdata?.email_template_id);
                localStorage.setItem(this.Storagestring+'-send_idd', this.popupform_userdata?.send_id);
                localStorage.setItem('UserEmail', this.popupform_userdata?.email_respon);

                this.gmail_id = this.popupform_userdata?.email_respon;
                this.send_idd = this.popupform_userdata?.send_id;
                this.email_template_id = this.popupform_userdata?.email_template_id;
            }
        })
    }

    get_campaign_detail() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, campiagn_id: this.campaign_id, menu: this.menu , Subdomain: this.Subdomain }, 'get_campiagn_details').subscribe(response => {

            this.responseData = response;
            this.isLoading = false;
            if (response.responseStatus == 1) {
                
                this.campaign           = response.data.campaign;
                this.campaign_form_id   = this.campaign.form_id;
                this.footer_page        = response.data.footer_page; 
                
                this.all_pages          = response.data.campaign_pages;
                this.total_page         = this.all_pages?.length || 0;
                this.current_page       = response.data.current_page;

                this.pdf_url = this.campaign.customer_unique_url + '/pdf/';
                // this.pdf_url = "http://localhost/in2in/front/pdf/";

                // this.newstr = this.campaign.campaign_title.toString().replace(" ", "-");
                this.newstr = this.Subdomain;

                if (this.campaign.favicon != "") {
                    this._document.getElementById('appIcon').setAttribute('href', this.assets_url + '/' + this.campaign.favicon);
                }

                if(this.current_page.meta_title != ''){
                    this.title.setTitle(this.current_page.meta_title);
                }else{
                    this.title.setTitle(this.campaign.manu_title);
                }

                this.meta.addTags([
                    { name: 'description', content: this.current_page.meta_description},
                    { name: 'keyword', content: this.current_page.meta_keyward},
                ])

                if(this.current_page.page_schema != '' && this.current_page.page_schema != null){

                    var headID = document.getElementsByTagName('head')[0];
                    var script = document.createElement('script');
                    script.type = 'application/ld+json';
                    headID.appendChild(script);
                    script.innerHTML = this.current_page.page_schema;
                }

                if (this.campaign.front_template == 2) {

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/css/style.css';


                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/lib/font-awesome/css/font-awesome.min.css';


                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/lib/bootstrap/css/bootstrap.min.css';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/mobile-nav/mobile-nav.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/js/main.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/bootstrap/js/bootstrap.bundle.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/jquery/jquery-migrate.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/jquery/jquery.min.js';
                
                }else if (this.campaign.front_template == 3) {

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme3/css/vendor/vendor.min.css';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme3/css/plugins/plugins.min.css';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme3/css/style.css';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/animation-text.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/circle-progress.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/countdown.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/counterup.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/images-loaded.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/isotope.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/jquery.wavify.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/lightgallery.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/masonry.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/plugins.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/some-plugins.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/swiper.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/vivus.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/wavify.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/waypoints.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/plugins/wow.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/vendor/bootstrap.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/vendor/jquery-3.5.1.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/vendor/jquery-migrate-3.3.0.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/vendor/modernizr-2.8.3.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/particles.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/script.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme3/js/main.js';

                    if (this.footer_page != '' && this.footer_page.page_type === 'Editor' && this.footer_page.page_editor != '') {
                        this.sanitized_footer   = this.sanitized.bypassSecurityTrustHtml(this.footer_page.page_editor);
                    }

                } else {
                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    link.id = 'widget_styles';
                    headID.appendChild(link);
                    link.href = 'assets/theme1/css/styles.css';

                    var linkk = document.createElement('link');
                    linkk.type = 'text/css';
                    linkk.rel = 'stylesheet';
                    linkk.id = 'widget_styles';
                    headID.appendChild(linkk);
                    linkk.href = 'assets/theme1/css/style.css';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/main.js';

                    var script = document.createElement('script');
                    headID.appendChild(script);
                    script.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js';
                    script.integrity = 'sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/jquery/jquery.min.js';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/bootstrap/bootstrap.bundle.min.js';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/jquery-easing/jquery.easing.min.js';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/counter/jquery.counterup.min.js';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/counter/jquery.waypoints.min.js';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/custom.js';
                }

                if (this.current_page.page_type === 'Editor' && this.current_page.page_editor != '' && this.campaign.front_template != 3) {
                    
                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/visualcomposer/custom.css';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilyrobotoregularsubsetgreek-extcyrillic-extgreeklatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Roboto%3Aregular&#038;subset=greek-ext%2Ccyrillic-ext%2Cgreek%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilylato300subsetlatin-extlatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Lato%3A300&#038;subset=latin-ext%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat500subsetcyrillic-extlatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A500&#038;subset=cyrillic-ext%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilylato300subsetlatinlatin-ext-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Lato%3A300&#038;subset=latin%2Clatin-ext&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundsimpledistbackgroundsimplebundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://in2in.in/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundSimple/dist/backgroundSimple.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundvideoyoutubedistbackgroundvideoyoutubebundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://in2in.in/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundVideoYoutube/dist/backgroundVideoYoutube.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibraryimagefilterdistimagefilterbundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://in2in.in/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/imageFilter/dist/imageFilter.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserratregularsubsetcyrillic-extlatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3Aregular&#038;subset=cyrillic-ext%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundzoomdistbackgroundzoombundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://in2in.in/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundZoom/dist/backgroundZoom.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat500subsetcyrilliccyrillic-extlatinlatin-extvietnamese-css';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A500&#038;subset=cyrillic%2Ccyrillic-ext%2Clatin%2Clatin-ext%2Cvietnamese&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat700subsetcyrilliccyrillic-extlatinlatin-extvietnamese-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A700&#038;subset=cyrillic%2Ccyrillic-ext%2Clatin%2Clatin-ext%2Cvietnamese&#038;ver=0-180';
                }

                if(this.current_page.page_type === 'Editor' && this.current_page.page_editor != ''){
                    this.sanitized_html = this.sanitized.bypassSecurityTrustHtml(this.current_page.page_editor);
                }

                this.previous_menu = this.current_page.previous_menu;
                this.next_menu = this.current_page.next_menu;

                if (this.current_page.page_type === 'embeded_video_url') {
                    this.embeded_video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.current_page.page_embeded_video_url);
                }

                if (this.campaign.campaign_form != "") {
                    this.get_campaign_form();
                }

                if (this.current_page.page_type === 'url') {
                    this.embeded_page_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.current_page.page_url);
                }

                if (this.current_page.contact_form != '' && this.current_page.contact_form !== 0) {
                    this.get_form();
                } else {
                    this.contact_form = [];
                    this.contact_form_field = [];
                }

                if (this.current_page.banner != '' && this.current_page.banner != null) {
                    this.get_banner();
                }

                if (this.current_page.form_position == "right") {
                    this.form_position = "offset-md-9 form_right";
                }

                if (this.current_page.form_position == "left") {
                    this.form_position = "form_left";
                }

                if (this.current_page.form_position == "center") {
                    this.form_position = "form_center";
                }

                if (this.current_page.contact_form_view_type == "modal" && this.current_page.contact_form != '' && this.current_page.contact_form != '0') {
                    setTimeout(() => {
                        this.openDialog();
                    }, 1000);
                }
            
            }else if (response.responseStatus == 2) {

            }
            setTimeout(() => {
                this.howmuchtime = new Array();
                this.scrollHeight = document.documentElement.scrollHeight;
                this.clientHeight = document.documentElement.clientHeight;
                var let_count = Math.round(this.scrollHeight/this.clientHeight);
                for (let index = 1; index <= let_count; index++) {
                    this.howmuchtime.push(index*this.clientHeight);
                }
                this.firstscrollfunction();
                this.EnquiryForm();
            },1000);
        })
        
    }
    
    firstscrollfunction(){
        this.scrollHeightt = document.documentElement.scrollHeight;
        this.clientHeightt = document.documentElement.clientHeight;
        this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, email_template_id: this.email_template_id, send_idd: this.send_idd, currentScroll:this.clientHeightt,total_page_height:this.scrollHeightt}, 'send_scroll_depth').subscribe(response2 => {
            if (response2.responseStatus == 1) {
            }
        })
    }

    @HostListener("document:scroll")
    scrollfunction(){
        
        var currentScroll = document.documentElement.scrollTop + document.documentElement.clientHeight;
        
        if (currentScroll > this.previousScroll){
            if(this.howmuchtime != undefined && currentScroll > this.howmuchtime[0]){
                this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, email_template_id: this.email_template_id, send_idd: this.send_idd, currentScroll:currentScroll,total_page_height:this.scrollHeight}, 'send_scroll_depth').subscribe(response2 => {
                    if (response2.responseStatus == 1) {
                         
                    }
                })
                this.howmuchtime.splice(0,1);
            }
        }
        this.previousScroll = currentScroll - document.documentElement.clientHeight;
    }

    ngAfterViewInit() {

        setTimeout(function () {
            $('.page_loader_class').addClass('fadeOut');
            document.querySelector('body').classList.remove('page-top');
        }, 800);
    }

    get_banner() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, banner_id: this.current_page.banner }, 'get_banner').subscribe(response2 => {

            if (response2.responseStatus == 1) {
                this.banner_data = response2.data;
            } else {
                this.banner_data = [];
            }
        })
    }

    open_campaign_form() {
        if(this.formtype != ''){ 
            const dialogRef = this.dialog.open(CampaigncontactformdialogComponent, {
                width: this.form_width,
                data: {
                    contact_form_button_campaign: this.submite_camp_btn,
                    button_name: this.contact_form_button_campaign,
                    campaign: this.campaign,
                    contact_form: this.contact_form_campaign,
                    contact_form_field: this.contact_form_field_campaign,
                    contactFormValidation: this.contactFormValidationcamp,
                    send_id : this.send_idd,
                    country : this.popupform_userdata?.country,  
                    list : this.popupform_userdata?.list,
                    user_id : this.popupform_userdata?.form_id,
                    utm_source : this.utm_sourcee_id,
                    company_name:this.popupform_userdata?.company_name,
                    form_type:this.formtype,
                    utm_medium: this.user_utm_medium,
                    utm_term: this.user_utm_term,
                },
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        }
    }

    get_form() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, form_id: this.current_page.contact_form , gmail : this.gmail_id , campaign_id : this.campaign_form_id  }, 'get_form').subscribe(response2 => {

            if (response2.responseStatus == 1) {
              
                this.contact_form           = response2.data?.form;
                this.submite_button_color   = this.contact_form?.submite_button_color;
                this.popup_form_btn         = this.contact_form?.submit_button_name;
                this.contact_form_field     = response2.data?.form_field;
                
                this.form_type = this.contact_form.template_form;
                if(this.form_type == 'right_image_form' || this.form_type == 'left_image_form'){
                    this.formwidth = '800px';
                }else if (this.form_type == 'top_image_form'){
                    this.formwidth = '450px';
                }
                this.setContactFormFieldValidation();
            }
            else if (response2.responseStatus == 2) {
                this.contact_form = [];
                this.contact_form_field = [];
                this.form_type = '';
            }
        })
    }

    get_campaign_form() {

        this.restApi.postData({ apikey: this.restApi.API_KEY, form_id: this.campaign.campaign_form , gmail : this.gmail_id , campaign_id : this.campaign_form_id }, 'get_form').subscribe(response2 => {

            if (response2.responseStatus == 1) {

                this.contact_form_campaign          = response2.data?.form;
                this.contact_form_field_campaign    = response2.data?.form_field;
                this.contact_form_button_campaign   = this.contact_form_campaign.submit_button_name;
                this.submite_camp_btn               = this.contact_form_campaign?.submite_button_color;

                this.formtype = this.contact_form_campaign.template_form;
                if(this.formtype == 'right_image_form' || this.formtype == 'left_image_form'){
                    this.form_width = '800px';
                }else if (this.formtype == 'top_image_form'){
                    this.form_width = '450px';
                }

                this.setContactFormFieldValidation_camp();
            }
            else if (response2.responseStatus == 2) {
                this.contact_form_campaign = [];
                this.contact_form_field_campaign = [];
                this.formtype = '';
            }
        })
    }

    openDialog() {
        if(this.form_type != ''){
            const dialogRef = this.dialog.open(ContactformdialogComponent, {
                width: this.formwidth,
                data: {
                    submite_button_color: this.submite_button_color,
                    button_name: this.popup_form_btn,
                    campaign: this.campaign,
                    current_page: this.current_page,
                    contact_form: this.contact_form,
                    contact_form_field: this.contact_form_field,
                    contactFormValidation: this.contactFormValidationn,
                    send_id : this.send_idd,
                    country :this.popupform_userdata?.country,  
                    list :this.popupform_userdata?.list,
                    user_id : this.popupform_userdata?.form_id,
                    utm_source : this.utm_sourcee_id,
                    company_name:this.popupform_userdata?.company_name,
                    form_type:this.form_type,
                    utm_medium: this.user_utm_medium,
                    utm_term: this.user_utm_term,
                },
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        }
    }

    setContactFormFieldValidation() {

        this.contactFormValidationn = JSON.parse(JSON.stringify(this.contactFormValidationnOriginal));

        if (this.contact_form_field && this.contact_form_field.length) {
            for (let index = 0; index < this.contact_form_field.length; index++) {

                if (this.contact_form_field[index].form_name == 'first_name' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.first_name = true;

                } else if (this.contact_form_field[index].form_name == 'last_name' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.last_name = true;

                } else if (this.contact_form_field[index].form_name == 'contact' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.contact = true;

                } else if (this.contact_form_field[index].form_name == 'gender' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.gender = true;

                } else if (this.contact_form_field[index].form_name == 'email' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.email = true;

                } else if (this.contact_form_field[index].form_name == 'company' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.company = true;

                } else if (this.contact_form_field[index].form_name == 'address' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.address = true;

                } else if (this.contact_form_field[index].form_name == 'language' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.language = true;

                } else if (this.contact_form_field[index].form_name == 'allow' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.allow = true;

                } else if (this.contact_form_field[index].form_name == 'description' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.description = true;

                } else if (this.contact_form_field[index].form_name == 'date_of_birth' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.date_of_birth = true;

                } else if (this.contact_form_field[index].form_name == 'marital_status' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.marital_status = true;

                } else if (this.contact_form_field[index].form_name == 'education' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.education = true;

                } else if (this.contact_form_field[index].form_name == 'aadhaar_card_no' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.aadhaar_card_no = true;

                } else if (this.contact_form_field[index].form_name == 'salary' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.salary = true;

                } else if (this.contact_form_field[index].form_name == 'company_address' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.company_address = true;

                } else if (this.contact_form_field[index].form_name == 'image' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.image = true;

                } else if (this.contact_form_field[index].form_name == 'job_title' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.job_title = true;
                }
            }
        }
    }

    uploadFileEvt(imgFile: any) {
        if (imgFile.target.files && imgFile.target.files[0]) {
            this.fileAttr = '';

            Array.from(imgFile.target.files).forEach((file: File) => {
                this.fileAttr += file.name + ' - ';
            });

            let reader = new FileReader();
            reader.onload = (e: any) => {
                let image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    let imgBase64Path = e.target.result;
                    
                    this.contactForm.get("image").setValue(imgBase64Path, { emitEvent: false });
                };
            };
            reader.readAsDataURL(imgFile.target.files[0]);

            this.fileInput.nativeElement.value = "";
        } else {
            this.fileAttr = 'Choose File';
        }
    }

    setContactFormFieldValidation_camp() {

        if (this.contact_form_field_campaign && this.contact_form_field_campaign.length) {
            for (let index = 0; index < this.contact_form_field_campaign.length; index++) {
                
                if (this.contact_form_field_campaign[index].form_name == 'first_name' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.first_name = true;

                }else if (this.contact_form_field_campaign[index].form_name == 'last_name' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.last_name = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'contact' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.contact = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'gender' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.gender = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'email' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.email = true;
                
                }else if (this.contact_form_field_campaign[index]?.form_name == 'company' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.company = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'address' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.address = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'language' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.language = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'allow' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.allow = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'description' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.description = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'date_of_birth' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.date_of_birth = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'marital_status' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.marital_status = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'education' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.education = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'aadhaar_card_no' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.aadhaar_card_no = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'salary' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.salary = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'company_address' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.company_address = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'image' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.image = true;
                
                }else if (this.contact_form_field_campaign[index].form_name == 'job_title' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.job_title = true;
                }
            }
        }
    }

    submitForm() {
        
        var all_value = this.contactForm.value;
        var status = 0;

        if (this.contactFormValidationn.first_name == true) {
            if (all_value.first_name == '') {
                this.contactFormValidationn.first_name_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.first_name_error_msg = "";
            }
        }

        if (this.contactFormValidationn.last_name == true) {
            if (all_value.last_name == '') {
                this.contactFormValidationn.last_name_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.last_name_error_msg = "";
            }
        }

        if (this.contactFormValidation.contact == true) {
            if (all_value.contact == null || all_value.contact == '') {
                this.contactFormValidation.contact_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidation.contact_error_msg = "";
            }
        }

        if(all_value.contact != null || all_value.contact != ''){
            if(!this.CheckNumber(all_value.contact)){
                this.contactFormValidation.contact_error_msg = "Please enter your correct 10 digit number";
                status++;  
            }else{
                this.contactFormValidation.contact_error_msg = "";
            }
        }
        
        if (this.contactFormValidationn.gender == true) {
            if (all_value.gender == '') {
                this.contactFormValidationn.gender_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.gender_error_msg = "";
            }
        }

        if (this.contactFormValidationn.email == true) {
            if (all_value.email == '') {
                this.contactFormValidationn.email_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.email_error_msg = "";
            }
        }

        if(all_value.email != '') {
            if(!this.CheckEmail(all_value.email)) {
                this.contactFormValidation.email_error_msg = "Please enter right email address";
                status++;  
            }else{
                this.contactFormValidation.email_error_msg = "";
            }
        }

        if (this.contactFormValidationn.company == true) {
            if (all_value.company == '') {
                this.contactFormValidationn.company_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.company_error_msg = "";
            }
        }

        if (this.contactFormValidationn.address == true) {
            if (all_value.address == '') {
                this.contactFormValidationn.address_error_msg = "Required field.";
            }else {
                this.contactFormValidationn.address_error_msg = "";
            }
        }

        if (this.contactFormValidationn.language == true) {
            if (all_value.language == '') {
                this.contactFormValidationn.language_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.language_error_msg = "";
            }
        }

        if (this.contactFormValidationn.allow == true) {
            if (all_value.allow == '') {
                this.contactFormValidationn.allow_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.allow_error_msg = "";
            }
        }

        if (this.contactFormValidationn.description == true) {
            if (all_value.description == '') {
                this.contactFormValidationn.description_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.description_error_msg = "";
            }
        }

        if (this.contactFormValidationn.date_of_birth == true) {
            if (all_value.date_of_birth == '') {
                this.contactFormValidationn.date_of_birth_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.date_of_birth_error_msg = "";
            }
        }

        if (this.contactFormValidationn.marital_status == true) {
            if (all_value.marital_status == '') {
                this.contactFormValidationn.marital_status_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.marital_status_error_msg = "";
            }
        }

        if (this.contactFormValidationn.education == true) {
            if (all_value.education == '') {
                this.contactFormValidationn.education_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.education_error_msg = "";
            }
        }

        if (this.contactFormValidationn.aadhaar_card_no == true) {
            if (all_value.aadhaar_card_no == '') {
                this.contactFormValidationn.aadhaar_card_no_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.aadhaar_card_no_error_msg = "";
            }
        }

        if (this.contactFormValidationn.salary == true) {
            if (all_value.salary == '') {
                this.contactFormValidationn.salary_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.salary_error_msg = "";
            }
        }

        if (this.contactFormValidationn.company_address == true) {
            if (all_value.company_address == '') {
                this.contactFormValidationn.company_address_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.company_address_error_msg = "";
            }
        }

        if (this.contactFormValidationn.job_title == true) {
            if (all_value.job_title == '') {
                this.contactFormValidationn.job_title_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.job_title_error_msg = "";
            }
        }
        
        if (this.contactFormValidationn.image == true) {
            if (all_value.image == '') {
                this.contactFormValidationn.image_error_msg = "Required field.";
                status++;
            }else {
                this.contactFormValidationn.image_error_msg = "";
            }
        }
       
        if (status > 0) {
            return false;
        }
        
        this.contactForm.reset();
        this.submitFormApi(all_value);
    }

    submitFormApi(postData = null) {

        this.isButtonLoading = true;

        if (this.current_page?.form_id != "") {
            postData.page_id = this.current_page?.form_id;
        }

        postData.campaign_id    = this.campaign.form_id;
        postData.contact_form   = this.current_page.contact_form;
        postData.send_id        = this.send_idd;
        postData.country        = this.popupform_userdata?.country;
        postData.list           = this.popupform_userdata?.list;
        postData.user_id        = this.popupform_userdata?.form_id;
        postData.utm_source     = this.utm_sourcee_id;
        postData.utm_medium     = this.user_utm_medium;
        postData.utm_term       = this.user_utm_term;

        this.restApi.postData({ apikey: this.restApi.API_KEY, form_data: postData }, 'submit_contact_form').subscribe(response2 => {
            
            if(postData.email != "") {
                localStorage.setItem('UserEmail', postData.email);
            }

            if (response2.responseStatus = 1) {
                if (this.current_page.page_type === 'PDF') {
                    window.open(this.BaseUrl + "download_pdf/download/" + this.current_page.page_pdf);
                }

                localStorage.setItem('UserEmail', this.gmail_id);
                this.isButtonLoading = false;
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            
            }else if (response2.responseStatus == 2) {
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            }
        })
    }

    banner_report(id) {
        
        this.restApi.postData({ apikey: this.restApi.API_KEY, page_name: this.current_page.manu_title, banner_id: id, page_id: this.current_page.form_id, campaign_id: this.campaign_id_send, user_email: this.gmail_id,send_id:this.send_idd }, 'banner_report').subscribe(response2 => {

            if (response2.responseStatus = 1) {

            }else if (response2.responseStatus == 2) {

            }
        })
    }

    startTimer() {
        this.time = 0;

        this.interval = setInterval(() => {
            if (this.time === 0) {
                this.time++;
                this.display = this.transform(this.time);
            } else {
                this.time++;
            }
        }, 1000);

        this.interval = setInterval(() => {
            this.display = this.transform(this.time);
        }, 10000);
    }

    transform(time) {
        
        this.page_id_send = this.current_page.form_id;
        this.campaign_id_send = this.current_page.campaign_id;

        if (time > 1) {
            this.query_type = "update";
        } else {
            this.query_type = "insert";
        }
        this.time = time;

        if(this.gmail_id != '' && this.gmail_id != null) {
            this.user_type  = "Known";
        }else{
            this.user_type  = "Unknown";
        }
        
        if(this.campaign_id_send != '' && this.campaign_id_send != null && this.time != ''){
            this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, total_time: this.time, query_type: this.query_type, page_tag: this.current_page?.page_tag, user_type: this.user_type, page_weight: this.current_page?.page_weight, email_template_id: this.email_template_id, job_department: this.popupform_userdata?.job_department, designation: this.popupform_userdata?.designation, company_name: this.popupform_userdata?.company_name, fname: this.popupform_userdata?.first_name, lname: this.popupform_userdata?.last_name , send_idd: this.send_idd, utm_source:this.utm_sourcee_id, utm_medium:this.user_utm_medium, utm_term:this.user_utm_term, country:this.popupform_userdata?.country , list:this.popupform_userdata?.list, user_id:this.popupform_userdata?.form_id, pageuniqueId: this.pageuniqueId , salt : this.salt_id}, 'send_campaiagn_report').subscribe(response2 => {
    
            })
        }
    }

    startTimeloader() {
        this.interval = setInterval(() => {
            if (this.timer === 0) {
                this.timer++;
            } else {
                this.timer++;
            }
            this.display = this.callBackFn();
        }, 15000);
    }

    callBackFn() {
        this.loaderview = false;
    }

    CheckNumber(number:any) {
        if(number.toString().length == 10){
            return true;
        }else{
            return false;
        }
    }

    CheckEmail(email:any) {

        var pattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
        if(email.match(pattern)) {
            return true;
        }else{
            return false;
        }
    }
    
    EnquiryForm(){

        var _this = this;

        $('.SubmitButton').on("click", function() {

            var form_type = $('#form_type').val();
            _this.EnquiryFormField.form_type = form_type;
            
            if(form_type == 'full_form' || form_type == 'need_hand'){

                var name        = $.trim($('#name').val());
                var email       = $.trim($('#email').val());
                var subject     = $.trim($('#subject').val());
                var description = $.trim($('#description').val());

                if (name == '') {
                    $('#name').val(''); 
                    $('#name_error').html("Please Enter Full Name!"); return false;
                }else {
                    $('#name_error').html('');
                }

                if (email == '') {
                    $('#email').val(''); 
                    $('#email_error').html("Please Enter Email ID!"); return false;
                }else {
                    $('#email_error').html('');
                }

                if(email != '' || email != null) {
                    if(!_this.CheckEmail(email)) {
                        $('#email_error').html("Please Enter Right Email Address!"); return false; 
                    }else{
                        $('#email_error').html('');
                    }
                }

                if(form_type == 'full_form'){
                    if (subject == '') {
                        $('#subject').val('');
                        $('#subject_error').html("Please Enter Subject!"); return false;
                    }else {
                        $('#subject_error').html('');
                    }
                    _this.EnquiryFormField.subject = subject;
                }

                if(form_type == 'need_hand'){
                    var department = $('#department').val();

                    if (department == null || department == '') {
                        $('#department_error').html("Please Select Department!"); return false;
                    }else {
                        $('#department_error').html('');
                    }
                    _this.EnquiryFormField.department = department;
                }

                if (description == '') {
                    $('#description').val('');
                    $('#description_error').html("Please Enter Description!"); return false;
                }else {
                    $('#description_error').html('');
                }

                _this.EnquiryFormField.name         = name;
                _this.EnquiryFormField.email        = email;
                _this.EnquiryFormField.description  = description;
            
            }else if(form_type == 'contact_form') {

                var contact_number = $.trim($('#contact_number').val());

                if (contact_number == null || contact_number == '') {
                    $('#contact_number').val('');
                    $('#contact_number_error').html("Please Enter Contact Number!"); return false;
                }else {
                    $('#contact_number_error').html('');
                }
            
                if(contact_number != null || contact_number != ''){
                    if(!_this.CheckNumber(contact_number)){
                        $('#contact_number_error').html("Please Enter Correct 10 Digit Number"); return false;
                    }else{
                        $('#contact_number_error').html('');
                    }
                }
                _this.EnquiryFormField.contact_number = contact_number;
            }

            if(form_type){
                $('.SubmitButton').prop('disabled', true);
                _this.SaveEnquiryForm(_this.EnquiryFormField);
            }
        });
    }

    SaveEnquiryForm(PostData = null){

        if (this.current_page?.form_id != "") {
            var page_id = this.current_page?.form_id;
        }
        var campaign_id = this.campaign.form_id;

        this.restApi.postData({apikey: this.restApi.API_KEY, page_id: page_id, campaign_id: campaign_id, form_type: PostData.form_type, name: PostData.name, email: PostData.email, subject: PostData.subject, description: PostData.description, contact: PostData.contact_number, department: PostData.department }, 'send_enquiry_email').subscribe(response => {
    
            if (response.Status = 1) {

                let snackBarRef = this._snackBar.open(response.Message, 'OK', {
                    duration: 5000
                });
            
            }else if (response.Status == 0) {
                let snackBarRef = this._snackBar.open(response.Message, 'OK', {
                    duration: 5000
                });
            }
            $('.SubmitButton').prop('disabled', false);
        })
    }

}   
